import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})

export class SideMenuService {
  public w480: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public w768: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public w991: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public w1150: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public w1440: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public desktopWidth: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public sideMenuOpened_BS = new BehaviorSubject<boolean>(false);


  public items: SideMenu[] = [
    {
      label: 'Dashboard',
      icon: 'pi pi-chart-bar',
      routerLink: '/dashboard/dashboard-layout',
      class1: 'dashboard',
    },
    {
      label: 'Manage Users',
      icon: 'pi pi-users',
      dropdown: true,
      expand: false,
      class1: 'users',
      items: [
        {
          label: 'Users List',
          routerLink: '/users/manage',
          class1: 'manage',
          class2: 'edit-user',
          class3: 'user-details',
        },
        {
          label: 'Staff List',
          routerLink: '/users/staff-users',
          class1: 'staff-users',
          class2: 'edit-staff',
          class3: 'add-staff',
          class4: 'staff-details',
        },
        {
          label: 'Roles',
          routerLink: '/users/roles',
          class1: 'roles',
          class2: 'add-role',
        },
      ],
    },
    {
      label: 'Wallet',
      icon: 'pi pi-wallet',
      routerLink: '/wallets/wallets-list',
      class1: 'wallets',
    },
    {
      label: 'Gateways',
      icon: 'pi pi-arrows-alt',
      routerLink: '/gateways/all-gateways',
      class1: 'gateways',
    },
    {
      label: 'Payment Methods',
      icon: 'fa-solid fa-credit-card',
      routerLink: '/payment-methods/payment-methods-list',
      class1: 'payment-methods',
    },
    {
      label: 'Currencies',
      icon: 'pi pi-dollar',
      routerLink: '/currencies/all-currencies',
      class1: 'currencies',
    },
    {
      label: 'Charts',
      icon: 'fa-solid fa-chart-pie',
      class1: 'charts',
      dropdown: true,
      expand: false,
      items: [
        {
          label: 'Order Analytics',
          routerLink: '/charts/order-analytics',
          class1: 'order-analytics',
        },
        {
          label: 'User Analytics',
          routerLink: '/charts/user-analytics',
          class1: 'user-analytics',
        },
      ],
    },
    {
      label: 'Exchange Direction',
      icon: 'pi pi-sitemap',
      routerLink: '/directions/exchange-directions',
      class1: 'directions',
    },
    {
      label: 'Orders',
      icon: 'pi pi-money-bill',
      class1: 'orders',
      dropdown: true,
      expand: false,
      count: 0,
      event: false,
      items: [
        {
          label: 'Exchanges',
          routerLink: '/orders/all-exchanges',
          class1: 'all-exchanges',
          class2: 'exchange-details',
          class3: 'user-exchanges',
        },
        {
          label: 'Withdraw',
          class1: 'withdraw-list',
          routerLink: '/orders/withdraw-list',
          class2: 'withdraw-details',
        },
        {
          label: 'Tob Up',
          routerLink: '/orders/tob-up-list',
          class1: 'tob-up-list',

          class2: 'tob-up-details',
        },
      ],
    },

    {
      label: 'Tickets',
      icon: 'pi pi-ticket',
      class1: 'ticket',
      class2: 'contact-us',
      class3: 'new-gateway',
      class4: 'reset-2fa',
      dropdown: true,
      expand: false,
      items: [
        {
          label: 'Tickets list',
          routerLink: '/ticket/ticket-list',
          class1: 'ticket-list',
          class2: 'ticket-details',
        },
        {
          label: 'Contacts Messages',
          routerLink: '/contact-us/contact-us-list',
          class1: 'contact-us-list',
          class2: 'contact-us-details',
        },
        {
          label: 'Request New Gateway',
          routerLink: '/new-gateway/requests-new-gateways',
          class1: 'requests-new-gateways',
          class2: 'requests-new-gateways-details',
        },
        {
          label: 'Reset 2FA Requests',
          routerLink: '/reset-2fa/reset-2fa-requests',
          class1: 'reset-2fa-requests',
          class2: '2fa-request-details'
        },
      ],
    },
    {
      label: 'Send Email',
      icon: 'pi pi-send',
      routerLink: '/email/send-email',
      class1: 'email',
    },
    {
      label: 'Footer Pages',
      icon: 'pi pi-credit-card',
      dropdown: true,
      expand: false,
      class1: 'footer-pages',
      items: [
        {
          label: 'Social Media',
          routerLink: '/footer-pages/social-media',
          class1: 'social-media',
        },
        {
          label: 'FAQ',
          routerLink: '/footer-pages/all-FAQs',
          class1: 'FAQ',
          class2: 'all-FAQs',
        },
        {
          label: 'Blog',
          routerLink: '/footer-pages/all-blogs',
          class1: 'blogs',
          class2: 'all-blogs',
        },
        {
          label: 'Why us',
          class1: 'why-us',
        },
        {
          label: 'Privacy Policy',
          class1: 'privacy-policy',
        },
        {
          label: 'Terms & Conditions',
          class1: 'terms-conditions',
        },
      ],
    },
  ];
  public itemsFooter: SideMenu[] = [
    {
      label: 'Settings',
      icon: 'pi pi-cog',
      class1: 'settings',
      dropdown: true,
      expand: false,
      items: [
        {
          label: 'Setting',
          routerLink: '/settings/setting',
          class1: 'setting',
          class2: 'twofa-settings',
        },
        {
          label: 'Web Setting',
          routerLink: '/settings/web-setting',
          class1: 'web-setting',
        },
      ],
    },
    {
      label: 'Logout',
      icon: 'pi pi-sign-out',
      logout: true,
    },
  ];

}
export interface SideMenu {
  label?: string;
  icon?: string;
  dropdown?: boolean;
  expand?: boolean;
  expanded?: boolean;
  class1?: string;
  class2?: string;
  class3?: string;
  class4?: string;
  routeActive?: boolean | null;
  routerLink?: string;
  items?: SideMenu[];
  count?: number;
  event?: boolean;
  eventValue?: number;
  lengthOfItems?: number;
  logout?: true;
}
